var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{attrs:{"id":"products-results-page"},on:{"submit":_vm.onSubmit}},[_c('section',{staticClass:"products-section"},[_c('div',{staticClass:"container oversized-container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 box-breadcrumb-s1"},[_c('span',[_vm._v("Home")]),_vm._v(" "),_c('router-link',{attrs:{"to":""}},[_vm._v("Modelos")])],1)]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-filters-mobile"},[(_vm.showFilters == true)?_c('div',{staticClass:"bg-filter-mv",on:{"click":function($event){_vm.showFilters = false}}}):_vm._e(),_vm._v(" "),_c('a',{staticClass:"btn-filters",on:{"click":function($event){_vm.showFilters = !_vm.showFilters}}},[_c('i',{staticClass:"fas fa-filter"}),_c('span',[_vm._v("Ver filtros")])])]),_vm._v(" "),_c('div',{staticClass:"col-lg col-filters",class:{ 'show-filters': _vm.showFilters == true }},[_c('h4',{staticClass:"d-lg-none _title"},[_c('span',[_c('strong',[_vm._v("Filtrar")])]),_vm._v(" "),_c('a',{staticClass:"btn-hide",on:{"click":function($event){_vm.showFilters = false}}},[_c('i',{staticClass:"fas fa-chevron-left"})])]),_vm._v(" "),_c('div',{staticClass:"filters-container"},[_c('div',{staticClass:"box-filters"},[_c('h6',{staticClass:"group-f-title"},[_vm._v("Categoría")]),_vm._v(" "),_c('b-form-group',{staticClass:"box-filters-group",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-checkbox-group',{attrs:{"aria-describedby":ariaDescribedby,"name":"fil-0"},model:{value:(_vm.formSearch.category),callback:function ($$v) {_vm.$set(_vm.formSearch, "category", $$v)},expression:"formSearch.category"}},_vm._l((_vm.categoria),function(c,cInx){return _c('b-form-checkbox',{key:'cInx-'+cInx,attrs:{"value":c.id}},[_vm._v("\n                    "+_vm._s(c.name)+"\n                  ")])}),1)]}}])})],1),_vm._v(" "),_c('div',{staticClass:"box-filters"},[_c('h6',{staticClass:"group-f-title"},[_vm._v("Forma")]),_vm._v(" "),_c('b-form-group',{staticClass:"box-filters-group",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-checkbox-group',{attrs:{"aria-describedby":ariaDescribedby,"name":"fil-1"},model:{value:(_vm.formSearch.shape),callback:function ($$v) {_vm.$set(_vm.formSearch, "shape", $$v)},expression:"formSearch.shape"}},_vm._l((_vm.formas),function(c,cInx){return _c('b-form-checkbox',{key:'cInx-'+cInx,attrs:{"value":c.id}},[_vm._v("\n                    "+_vm._s(c.name)+"\n                  ")])}),1)]}}])})],1),_vm._v(" "),_c('div',{staticClass:"box-filters"},[_c('h6',{staticClass:"group-f-title"},[_vm._v("Materiales")]),_vm._v(" "),_c('b-form-group',{staticClass:"box-filters-group",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-checkbox-group',{attrs:{"aria-describedby":ariaDescribedby,"name":"fil-2"},model:{value:(_vm.formSearch.brand),callback:function ($$v) {_vm.$set(_vm.formSearch, "brand", $$v)},expression:"formSearch.brand"}},_vm._l((_vm.materiales),function(b,bInx){return _c('b-form-checkbox',{key:'bInx-'+bInx,attrs:{"value":b.id}},[_vm._v("\n                    "+_vm._s(b.name)+"\n                  ")])}),1)]}}])})],1),_vm._v(" "),_c('div',{staticClass:"box-filters"},[_c('h6',{staticClass:"group-f-title"},[_vm._v("Recubrimiento ")]),_vm._v(" "),_c('b-form-group',{staticClass:"box-filters-group",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-checkbox-group',{attrs:{"aria-describedby":ariaDescribedby,"name":"fil-3"},model:{value:(_vm.formSearch.recubrimiento),callback:function ($$v) {_vm.$set(_vm.formSearch, "recubrimiento", $$v)},expression:"formSearch.recubrimiento"}},_vm._l((_vm.recubrimientos),function(b,bInx){return _c('b-form-checkbox',{key:'bInx-'+bInx,attrs:{"value":b.id}},[_vm._v("\n                    "+_vm._s(b.name)+"\n                  ")])}),1)]}}])})],1),_vm._v(" "),_c('div',{staticClass:"box-filters"},[_c('h6',{staticClass:"group-f-title"},[_vm._v("Precio")]),_vm._v(" "),_c('b-form-group',{staticClass:"box-filters-group",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{attrs:{"aria-describedby":ariaDescribedby,"name":"fil-3"},model:{value:(_vm.formSearch.price),callback:function ($$v) {_vm.$set(_vm.formSearch, "price", $$v)},expression:"formSearch.price"}},_vm._l((_vm.price),function(p,pInx){return _c('b-form-radio',{key:'pInx-'+pInx,attrs:{"value":p.id}},[_vm._v("\n                    "+_vm._s(p.name)+"\n                  ")])}),1)]}}])})],1),_vm._v(" "),_c('div',{staticClass:"box-filters"},[_c('h6',{staticClass:"group-f-title"},[_vm._v("Descuentos")]),_vm._v(" "),_c('b-form-group',{staticClass:"box-filters-group",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{attrs:{"aria-describedby":ariaDescribedby,"name":"fil-4"},model:{value:(_vm.formSearch.discount),callback:function ($$v) {_vm.$set(_vm.formSearch, "discount", $$v)},expression:"formSearch.discount"}},_vm._l((_vm.discounts),function(d,dInx){return _c('b-form-radio',{key:'dInx-'+dInx,attrs:{"value":d.id}},[_vm._v("\n                    "+_vm._s(d.name)+"\n                  ")])}),1)]}}])})],1)])]),_vm._v(" "),_c('div',{staticClass:"col-lg col-results"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-title"},[_c('h4',{staticClass:"title-s1"},[_vm._v("Modelos")])]),_vm._v(" "),_vm._l((_vm.products),function(p,pInx){return _c('div',{key:'pInx-'+pInx,staticClass:"col-sm-6 col-lg-6 col-xl-4 box-product-sample-s1"},[_c('router-link',{staticClass:"box-link",attrs:{"to":"/modelos/1"}},[_c('div',{staticClass:"placed-backg box-image",style:({ backgroundImage: 'url('+p.imageUrl+')' })},[_c('img',{attrs:{"src":"public/images/shared/glasses.png"}})]),_vm._v(" "),_c('div',{staticClass:"box-descr"},[_c('h6',{staticClass:"name"},[_vm._v("Nombre del producto")]),_vm._v(" "),_c('div',{staticClass:"descr"},[_c('p',[_vm._v("Producto, descripción, información extra y más va aquí")])]),_vm._v(" "),_c('h6',{staticClass:"price"},[_vm._v("$ "+_vm._s(Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(p.price)))]),_vm._v(" "),_c('p',{staticClass:"mt-3"},[_c('span',{staticClass:"btn-more"},[_vm._v("VER MÁS")])])])])],1)})],2),_vm._v(" "),(_vm.pagination.total_products > 6)?_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-12 text-center"},[_c('div',{staticClass:"d-inline-block mx-0 col-pagination-sample-1"},[_c('b-pagination-nav',{attrs:{"link-gen":_vm.linkGen,"number-of-pages":_vm.pagination.total,"use-router":""}})],1)])]):_vm._e()])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }