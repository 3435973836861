<template lang="html">
  <div id="about-us-page">

    <section class="main-section">
      <i class="bg"></i>

      <div class="container oversized-container">
        <div class="row">
          <div class="offset-lg-6 col-lg-6 col-xl-5 col-contant">
            <h1 class="title">Nosotros</h1>

            <p>
              Lorem ipsum dolor sit amet, tempor incididunt et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaeca.
            </p>
            <p class="mt-2">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis laborum.
            </p>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
