<template lang="html">
  <div id="home-page">

    <section class="banner-section" data-aos="fade">
      <swiper class="swiper desktop" :options="bannersOptions">
        <swiper-slide v-for="(b, bhInx) in banners.desktop" :key="'bhInx-'+bhInx">
          <div class="placed-backg box" v-bind:style="{ backgroundImage: 'url('+b.imageUrl+')' }">
            <img src="public/images/pages/home/banner.png">
          </div>
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>

      <swiper class="swiper mobile" :options="bannersOptions">
        <swiper-slide v-for="(b, bhmInx) in banners.mobile" :key="'bhmInx-'+bhmInx">
          <div class="placed-backg box" v-bind:style="{ backgroundImage: 'url('+b.imageUrl+')' }">
            <img src="public/images/pages/home/banner-m.png">
          </div>
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </section>

    <section class="container oversized-container products-section">
      <div class="d-block mb-1 mb-md-3">
        <h2 class="title-s1">Productos Más Populares</h2>
      </div>

      <div class="box-products">
        <swiper class="swiper" :options="productsOptions">
          <swiper-slide v-for="(p, phInx) in products" :key="'phInx-'+phInx">
            <div class="col-12 px-0 box-product-sample-s1">
              <router-link class="box-link" to="/modelos/1">
                <!-- <span class="bubble">Nuevo</span> -->
                <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+p.imageUrl+')' }">
                  <img src="public/images/shared/glasses.png">
                </div>

                <div class="box-descr">
                  <h6 class="name">Nombre del producto</h6>

                  <div class="descr">
                    <p>Producto, descripción, información extra y más va aquí</p>
                  </div>

                  <h6 class="price">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(p.price) }}</h6>

                  <p class="mt-3">
                    <span class="btn-more">VER MÁS</span>
                  </p>
                </div>
              </router-link>
            </div>
          </swiper-slide>

          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </section>

    <section class="container oversized-container banner-lg-section">
      <div class="box-banner">
        <router-link to="/contacto">
          <img src="public/images/pages/home/banner-b-1.jpg">
        </router-link>
      </div>
    </section>

    <section class="container oversized-container categories-section">
      <div class="d-block mb-2 mb-sm-4">
        <h2 class="title-s1">Comprar por Categoría</h2>
      </div>

      <div class="row box-categories">
        <div class="col-lg-6 col-xl-5 col-category">
          <router-link class="t-150" to="/modelos?cat=1">
            <img src="public/images/pages/home/cat-1.jpg">
          </router-link>
        </div>

        <div class="col-lg-6 col-xl-5 col-category">
          <router-link class="t-150" to="/modelos?cat=2">
            <img src="public/images/pages/home/cat-2.jpg">
          </router-link>
        </div>
      </div>
    </section>

    <section class="container oversized-container banner-lg-section banner-c">
      <div class="box-banner">
        <router-link to="/modelos">
          <img src="public/images/pages/home/banner-c-1.jpg">
        </router-link>
      </div>
    </section>

    <section class="container oversized-container products-section">
      <div class="d-block mb-1 mb-md-3">
        <h2 class="title-s1">Mejores Ofertas</h2>
      </div>

      <div class="box-products">
        <swiper class="swiper" :options="productsOptions">
          <swiper-slide v-for="(p, phInx) in products2" :key="'phInx-'+phInx">
            <div class="col-12 px-0 box-product-sample-s1">
              <router-link class="box-link" to="/modelos/1">
                <!-- <span class="bubble">Nuevo</span> -->
                <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+p.imageUrl+')' }">
                  <img src="public/images/shared/glasses.png">
                </div>

                <div class="box-descr">
                  <h6 class="name">Nombre del producto</h6>

                  <div class="descr">
                    <p>Producto, descripción, información extra y más va aquí</p>
                  </div>

                  <h6 class="price">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(p.price) }}</h6>

                  <p class="mt-3">
                    <span class="btn-more">VER MÁS</span>
                  </p>
                </div>
              </router-link>
            </div>
          </swiper-slide>

          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </section>


  </div>
</template>

<script>
export default {
  data() {
    return {
      banners: {
        desktop: [
          { imageUrl: 'public/images/pages/home/banner-1.jpg' },
          { imageUrl: 'public/images/pages/home/banner-1.jpg' },
          { imageUrl: 'public/images/pages/home/banner-1.jpg' },
        ],

        mobile: [
          { imageUrl: 'public/images/pages/home/banner-1-m.jpg' },
          { imageUrl: 'public/images/pages/home/banner-1-m.jpg' },
          { imageUrl: 'public/images/pages/home/banner-1-m.jpg' },
        ]
      },

      products: [
        { imageUrl: 'public/images/pages/products/glasses-1.jpg', price: '180' },
        { imageUrl: 'public/images/pages/products/glasses-2.jpg', price: '89' },
        { imageUrl: 'public/images/pages/products/glasses-3.jpg', price: '196' },
        { imageUrl: 'public/images/pages/products/glasses-4.jpg', price: '119' },
        { imageUrl: 'public/images/pages/products/glasses-1.jpg', price: '169' },
      ],

      products2: [
        { imageUrl: 'public/images/pages/products/glasses-5.jpg', price: '180' },
        { imageUrl: 'public/images/pages/products/glasses-6.jpg', price: '89' },
        { imageUrl: 'public/images/pages/products/glasses-7.jpg', price: '196' },
        { imageUrl: 'public/images/pages/products/glasses-8.jpg', price: '119' },
        { imageUrl: 'public/images/pages/products/glasses-1.jpg', price: '169' },
      ],

      // == Carousel options ==
      bannersOptions: {
        effect: 'fade',
        loop: true,
        speed: 700,

        autoplay: {
          delay: 4000,
          disableOnInteraction: false
        },

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },

      productsOptions: {
        slidesPerView: 4,
        spaceBetween: 30,

        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },

        breakpoints: {
          1400: {
            slidesPerView: 4,
          },
          1100: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
          },
          1: {
            slidesPerView: 1,
          },
        }
      }
      // == ==
    }
  }
}
</script>
